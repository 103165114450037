"use client";

import type { UserTokenV2 } from "@/hl-common/types/UserToken";
import * as Sentry from "@sentry/nextjs";
import { useEffect } from "react";

export const SentryUser = ({
  userToken,
}: { userToken: UserTokenV2 | null }) => {
  // set user in sentry scope
  useEffect(() => {
    const scope = Sentry.getCurrentScope();
    if (userToken?.id) {
      scope.setUser({
        id: userToken.id,
      });
    } else {
      scope.setUser(null);
    }
  }, [userToken?.id]);

  return null;
};
